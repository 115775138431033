
import CreateButton from '@/components/Crud/CreateButton.vue';
import http from '@/components/services/http-common';
import apiService from '@/components/services/ApiServices';
import EmployeeCreateDialog from '../Create';
import Excel from '../Excel';
import DataFilter from '@/components/DataFilter';
import * as moment from "moment/moment";
import ImportUpload from '@/components/ImportUpload';
import ImportExcel from '../Import';
import EmployeeTypeDetailService from './EmployeeTypeDetailService';
import EmployeeTypeDetailExportDialog from '../EmployeeTypeDetailExportDialog';
import MyBreadcrumb from '@/components/MyBreadcrumb'

import _ from 'lodash';

export default {
    name: 'Employee-index',
    components: {
        CreateButton,
        'excel': Excel,
        'create-dialog': EmployeeCreateDialog,
        'data-filter': DataFilter,
        'import-upload': ImportUpload,
        'import-excel':ImportExcel,
        EmployeeTypeDetailExportDialog,
        MyBreadcrumb
    },
    props: ["candidate", "icon"],
    computed: {
        // searchs() {
        //     return this.headers.filter(a => (a.filter)).map(a => {
        //         return a.filter.search;
        //     });
        // },
        // searchAttrs() {
        //     return this.headers.filter(a => (a.filter)).map(a => {
        //         return a.value;
        //     });
        // },
        
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        candidate: {
            handler(newVal, oldVal) {
                console.log(newVal, oldVal);
            }
        },
        // options: {
        //     handler (val) {
        //         this.fetchData();
        //     },
        //     deep: true,
        // },
        'form.searchs': {
            handler(newVal, oldVal) {
                this.options.page = 1;
            }
        },
        'form.search':{
            handler() {
            this.options.page = 1;
            }
        },
        
        tableParams: {
            handler (val) {
                // this.fetchData();
            },
            deep: true,
        },
        
        filters: {
            handler: _.debounce(function(){
                    this.tableParams.page=1;
                    this.fetchData();
                }, 1500),
            deep: true,
        },
    },
    data () {
        return {
            DEPARTMENT_URL: "departments",
            IMPORT_URL: "employees/import",
            breadcrumb: [
                {text: "Danh sách nhân viên ", to: {name: 'Employee'}}
            ],
            //filter

            filter: {
                menu: false,
                search: "",
                searchAttr: "EmployeeName",
                department: "",
                teamCode: "",

                from: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
                fromOns: [
                    {text: "Sinh nhật", value: "birthDayCeleb"},
                    {text: "Ngày sinh", value: "birthDay"},
                    {text: "Ngày nhận việc", value: "hireDate"},
                ],
                fromOn: "",

                searchAttrs: [
                    {text: "Tên nhân viên", value: "EmployeeName"},
                    {text: "MSNV", value: "EmployeeCode"},
                ],
                FilterAttrs: [
                    {text: "Phòng", items: [] }
                ],
                FilterOn:["DepartmentCode"],
            },

            //data table
            items: [],
            rowCount: 0,
            
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:100
            },
            exelLoading: false,
            
            departmentSearch:null,
            departmentTypes:[],
            departments: [],
            teams: [],
            positions: [],
            positionStates: [],
            cities:[],
            banks:[],
            empTypes: [],
            filterExpand: false,
            filters:{
                code: null,
                EmpTypeCode: null,
                name: null,
                gender: null,
                birthDay: '%',
                hireDate: null,
                departmentTypeCode: null,
                departmentCode: null,
                teamCode: null,
                positionCode: null,
                isDraft:false,
                status: 'on',
                bankCode: null,
                bankNumber: null,
                staticDate: moment().format('YYYY-MM-DD'),
                employeeReturn: 2
            },
            excel:false,

            isLoading: true,
            item:null,
            dialog: false,
            dialogDelete: false,
            IDdateMenu: false,
            HireMenu: false,
            headers: [
                { text: this.$t('view.employee.actions'), value: 'actions', sortable: false, width: "7rem", class:"with-divider", cellClass:"with-divider row-divider"},
                { text: this.$t('view.employee.code'), value: 'code', sortable: true, width: "auto", class:"with-divider", cellClass:"with-divider row-divider"},
                { text: this.$t('view.employee.employee_return'), value: 'employeeReturn', width: "100px", class:"with-divider", cellClass:"with-divider row-divider" },
                // { text: this.$t('view.employee.employee_type'), value: 'currentEmployeeTypeName', sortable: false, width: "auto", class:"with-divider", cellClass:"with-divider row-divider"},
                { text: this.$t('view.employee.name'), align: 'start', value: 'name', width: "200px", class:"with-divider", cellClass:"with-divider row-divider"},
                // { text: 'Giới tính', value: 'gender', width:"80px"},
                { text: this.$t('view.employee.date_of_birth'), value: 'birthDay', width:"120px", class:"with-divider", cellClass:"with-divider row-divider" },
                { text: this.$t('view.employee.start_date'), value: 'hireDate', width:"120px", class:"with-divider", cellClass:"with-divider row-divider" },
                { text: this.$t('view.employee.department_type'), value: 'currentDepartmentTypeName', width: "auto", class:"with-divider", cellClass:"with-divider row-divider" },
                { text: this.$t('view.employee.department'), value: 'currentDepartmentName', width:"auto", class:"with-divider", cellClass:"with-divider row-divider" },
                { text: "Bộ phận", value: 'teamName', width:"200", class:"with-divider", cellClass:"with-divider row-divider" },
                { text: this.$t('view.employee.position'), value: 'currentPositionName', width:"120px", class:"with-divider", cellClass:"with-divider row-divider"},
                { text: this.$t('view.employee.position_state'), value: 'currentPositionStateName', width:"120px", class:"with-divider", cellClass:"with-divider row-divider"},
                { text: this.$t('view.employee.asm'), value: 'asm', width: "175px", class:"with-divider", cellClass:"with-divider row-divider" },
                { text: this.$t('view.employee.city'), value: 'cityName', width: "120px", class:"with-divider", cellClass:"with-divider row-divider" },
                // { text: 'Ngân hàng', value: 'bank', width: "200px" },
                // { text: 'Số tài khoản', value: 'bankNumber', width: "200px" },
                // { text: 'Email', value: 'mail', width: "200px" },
                { text: this.$t('view.employee.sync_elearning'),value: 'clsSync', width: "auto", cellClass:"with-divider row-divider" },

                // { text: 'Avatar', value: 'avatar', sortable: false, align:"center", width: "3rem"},
                // { text: 'Quận', value: 'districtName', width: "200px" },
                // { text: 'Phường', value: 'wardName', width: "200px" },
                // { text: 'Địa chỉ', value: 'address', width: "200px" },
                // { text: 'Mới chuyển', value: 'isDraft', width: "200px"},
            ],
            
            targetItem: undefined,
            form: {
                search:"",
                searchArr: [],
            },
            endpoint: process.env.VUE_APP_BASE_URL,

            //import
            quitImportFile: undefined,
            quitImportDialog: false,

            assignmentImportFile: undefined,
            assignmentImportDialog: false,

            detailImportFile:undefined,
            detailImportDialog:false,
            
            birthDayDialogExport: undefined,
            birthDayMonthExport: moment().startOf('month').format('YYYY-MM-DD'),

            workingExportDialog:false,
            exportWorkingFilter: {
                listType: "%",
                departmentTypes: "",
                DepartmentCodes: "",
                PositionCodes: "",
                PositionStateCodes:"",
                asmCodes:"",
                religionCodes: "",
                current: true,
                toDate: moment().endOf("months").format("YYYY-MM-DD"),
                hireDateFrom: moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
                hireDateTo: moment('2100-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD')
            },

            promoteDetailDialogExport: false,
            statusDetailDialogExport:false,
            rotateDetailDialogExport:false,

            exportWorkingFilterData:{
                departmentTypes: [],
                departments: [],
                positions: [],
                positionStates:[],
                asms:[],
                religions: [],
            },

            //erp new product
            dialogERPNewProduct: false,
            ERPNewProductFilter: {
                fromDate: moment().startOf("months").format("YYYY-MM-DD"),
                toDate: moment().endOf("months").format("YYYY-MM-DD"),
            },

            employeeTypeDetailExportDialog: false,

        }
    },
    methods: {
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                (txt) =>{
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        hasHttp(url){
            let stringUrl = String(url)
            return stringUrl.includes("http");
        },
        blockClick(event)
        {
            event.stopPropagation()
        },
        syncingCls(id) {
            return http.get("employees/sync-cls/"+id).then(res => {
            })
        },
        clearAsmCls() 
        {
            return http.get("employees/clear-sync-cls").then(res => {

            })
        },
        SetAsmCls() 
        {
            return http.get("employees/set-asm-sync-cls").then(res => {

            })
        },
        syncAsmUserCLS() 
        {
            return http.get("syncElearning/sync-employees").then(res => {
            })
        },

        postERP (item) {
            return http.post("SyncERP/Employee", null, {
                params: {
                    employeeCode: item.code
                }
            });
        },
        
        putERP (item) {
            return http.put("SyncERP/Employee", null, {
                params: {
                    employeeCode: item.code
                }
            });
        },

        createElearning(item) {
            return http.get("syncElearning/create-employee", {
                params: {
                    employeeCode: item.code
                }
            });
        },

        newEmployeeLeave(item) {
            return http.get("employeeResource/add-new-employee-leaves", {
                params: {
                    employeeCode: item.code
                }
            });
        },

        statusIconConvert(status)
        {
            var returnObj = {icon: "mdi-help", color: "secondary"}
            switch(status)
            {
                case "Hoạt Động":{
                    returnObj = {icon: "mdi-briefcase", color: "success"}
                    break;
                }
                case "Tạm Nghỉ":{
                    returnObj = {icon: "mdi-pause", color: "orange"}
                    break;
                }
                case "Thai Sản":{
                    returnObj = {icon: "mdi-human-pregnant", color: "indigo"}
                    break;
                }
                case "Nghỉ Việc":{
                    returnObj = {icon: "mdi-briefcase-variant-off", color: "red accent-1"}
                    break;
                }
                case "Ẩn":{
                    returnObj = {icon: "mdi-eye-off", color: "grey"}
                    break;
                }
                case "Không nhận việc":{
                    returnObj = {icon: "mdi-account-remove", color: "purple accent-1"}
                    break;
                }
            }
            return returnObj;
        },
        denyOffer(item)
        {
            console.log(item.code);
            return http.get("employees/deny-offer/"+item.code).then(res=>{
                this.fetchData();
            });
        },
        preventHeaderHandler(e)
        {
            e.stopPropagation();
        },
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        openStatistic(){
            // console.log("statistic")
        },
        fetchData () {
            this.isLoading = true;
            return http.get('employees', {
                            params: this.params
                        }).then(res => {
                console.log(res)
                this.isLoading = false;
                this.items = res.data;
                // this.rowCount = res.data.rowCount;
                this.isLoading = false;
            }).catch(err => {
                console.log(err);
            });
        },

        fetchBank(){
            return http.get('banks')
            .then(res => {
                this.banks=[];
                this.banks.push({name: 'Chưa có tài khoản', code: 'none'})
                this.banks = this.banks.concat(res.data);
                console.log(this.banks, '*******');
            })
        },

        fetchDepartment() {
            apiService.fetchDepartment(this.departmentSearch)
            .then(res => {
                this.departments = res.data.filter(x=>!x.parentCode);
                this.exportWorkingFilterData.departments = res.data;
            })
            .catch(err => console.log(err))
        },
        
        fetchDepartmentType () {
            return http("departmentTypes/fetch").then(res => {
                this.departmentTypes = res.data;
                this.exportWorkingFilterData.departmentTypes = res.data;
            })
        },
        fetchPositions () {
            return http("positions/fetch").then(res => {
                this.positions = res.data;
                this.exportWorkingFilterData.positions = res.data;
            })
        },

        fetchPositionStates() {
            return http.get("positionStates").then(res => {
                this.positionStates = res.data;
                this.exportWorkingFilterData.positionStates = res.data;
            })
        },
        fetchCity() {
            return http.get("cities/fetch").then(res=> {
                this.cities=res.data;
            })
        },

        fetchAsm() {
            return http.get("employees/fetch").then(res => {
                this.exportWorkingFilterData.asms = res.data;
            })
        },
        fetchReligion() {
            return http.get("religions").then(res => {
                this.exportWorkingFilterData.religions = res.data;
            });
        },
        fetchEmpType() {
            this.employeeTypeDetailService.fetchEmployeeType().then(d=> {
                this.empTypes = d
            })
        },

        getTeam() {
            return http.get(`departments/team/${this.filters.departmentCode}`).then(r => {
                this.teams = r.data.data
                this.filters.teamCode = null
            })
        },

        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        editItem(item) {
            this.dialog= true;
            this.item = item;
        },
        contractItem(item) {
            this.$refs.contractDialog.item = item;
            this.$refs.contractDialog.fetchEmployee(item.code);
            this.$refs.contractDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete("employees/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        importQuit(){
            let formData = new FormData();
            formData.append("file", this.quitImportFile);
            return http.post("employees/import-quit-employees", formData).then(res => {
                console.log(res.data);
            });
        },

        importAssignment(){
            let formData = new FormData();
            formData.append("file", this.assignmentImportFile);
            return http.post("EmployeePromoteDetails/import", formData).then(res => {
                console.log(res.data);
            });
        },
        importAssignmentShallow(){
            let formData = new FormData();
            formData.append("file", this.assignmentImportFile);
            return http.post("employees/import-assignment-employees-shallow", formData).then(res => {
                console.log(res.data);
            });
        },

        importDetail() {
            let formData = new FormData();
            formData.append("file", this.detailImportFile);
            this.isLoading = true;
            return http.post("employees/import-detail", formData).then(res => {this.isLoading = false;})
        },

        exportBirthDay(){
            return http.get("employees/export-birthDay", {
                params: {
                    month: this.birthDayMonthExport
                },
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `SINH_NHAT_${moment(this.birthDayMonthExport).format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },
        
        exportWorking(){
            this.exelLoading = true
            return http.get("employees/export-working", {
                params: this.exportWorkingFilter,
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH SACH_NHAN_VIEN_${moment(this.exportWorkingFilter.toDate).format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).finally(() => {
                this.exelLoading = false
            });
        },

        
        exportEmployeePromoteDetails(){
            return http.get("employeePromoteDetails/export", {
                // params: this.exportWorkingFilter,
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH SACH_NHAN_VIEN_${moment(this.exportWorkingFilter.toDate).format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },
        
        exportEmployeeStatusDetails(){
            return http.get("employeeStatusDetails/export", {
                // params: this.exportWorkingFilter,
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH SACH_NHAN_VIEN_${moment(this.exportWorkingFilter.toDate).format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },
        
        exportEmployeeRotateDetails(){
            return http.get("employeeRotationDetails/export", {
                // params: this.exportWorkingFilter,
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH SACH_NHAN_VIEN_${moment(this.exportWorkingFilter.toDate).format("MM-YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },

        exportERPNewProduct(){
            return http.get("syncERP/get-new-product", {
                params: this.ERPNewProductFilter,
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH SACH_HANG_HOA_MOI${moment(this.exportWorkingFilter.toDate).format("__DD_MM_YYYY")}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        },

        unauthorizeAlert () {
            this.$Swal.fire({
                icon: 'error',
                title: 'Không có phân quyền',
                text: 'Bạn không có quyền sử chức năng này',
                // footer: '<a href>Why do I have this issue?</a>'
            })
        },
    },
    employeeTypeDetailService: null,
    created () {
        this.employeeTypeDetailService = new EmployeeTypeDetailService();
    },
    mounted () {
        // this.fetchData();
        this.isLoading = false;
        this.fetchBank();
        this.fetchDepartment();
        this.fetchPositions();
        this.fetchPositionStates();
        this.fetchCity();
        this.fetchDepartmentType();
        this.fetchAsm();
        this.fetchReligion();
        this.fetchEmpType();
    }
}